$(document).ready(function() {
 
  $("#brands").owlCarousel({
 
      autoPlay: 5000,
      items : 3,
      itemsDesktop : false,
      itemsScaleUp: true,
      slideSpeed : 1000,
      navigation : true,
      navigationText : ["<",">"],
      pagination : false,
      responsive : false
 
  });
 
});